<template>
  <v-container>
    <h1>Reportes</h1>
  </v-container>
</template>

<script>
export default {
  name: 'ReportesView'
};
</script>









