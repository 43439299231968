<template>
  <v-container>
    <v-card class="elevation-3">
      <v-card-title class="custom-bg">
        <span class="headline white--text">Carga de Archivos</span>
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="uploadFile" enctype="multipart/form-data">
          <v-text-field
              v-model="folio"
              label="Folio"
              @input="fetchUploadedFiles"
              required
          ></v-text-field>

          <v-select
              v-model="etapa"
              :items="etapas"
              label="Etapa"
              required
          ></v-select>

          <v-file-input
              v-model="archivo"
              label="Archivo"
              prepend-icon="mdi-paperclip"
              required
          ></v-file-input>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="resetForm">
              <v-icon left>mdi-close</v-icon> Cancelar
            </v-btn>
            <v-btn text type="submit">
              <v-icon left>mdi-content-save</v-icon> Subir Archivo
            </v-btn>
          </v-card-actions>

          <v-alert
              v-if="message"
              :type="messageType"
              class="mt-4"
              dismissible
          >
            {{ message }}
          </v-alert>
        </v-form>

        <!-- Indicador de carga -->
        <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            class="mt-4"
            style="margin: auto;"
        ></v-progress-circular>

        <!-- Tabla de archivos cargados -->
        <v-card-subtitle class="mt-4">Archivos Cargados</v-card-subtitle>
        <v-data-table
            :headers="headers"
            :items="archivosCargados"
            :items-per-page="5"
            class="elevation-1"
        >
          <template #item="{ item }">
            <tr>
              <td>{{ item.folio }}</td>
              <td>{{ item.etapa }}</td>
              <td>{{ item.nombre_archivo }}</td>
              <td>
                <v-btn
                    icon
                    @click="deleteFile(item.folio, item.etapa)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from 'axios';
const API_URL = process.env.VUE_APP_API_URL;

export default {
  data() {
    return {
      folio: '',

      etapa: 'Selecciona',
      archivo: null,
      message: '',
      messageType: 'success',
      loading: false,
      // Agregar una propiedad para el folio anterior
      previousFolio: null,

      etapas: [
        'Selecciona',
        'ALTA',
        'EN DIAGNÓSTICO',
        'PREPARANDO COTIZACIÓN',
        'COTIZACIÓN EN ESPERA DE AUTORIZACIÓN',
        'EN SERVICIO',
        'EN PROCESO DE EMBARQUE',
        'ENVIADO',
        'ENTREGADO'
      ],
      archivosCargados: [], // Lista de archivos cargados
      headers: [
        { text: 'Folio', value: 'nombre' },
        { text: 'Etapa', value: 'etapa' },
        { text: 'Archivo', value: 'fecha_carga' },
        { text: 'Acciones', value: 'acciones', sortable: false }
      ],
    };
  },
  methods: {
    async uploadFile() {
      if (!this.archivo) {
        this.message = 'Por favor seleccione un archivo';
        this.messageType = 'error';
        return;
      }

      if ( this.etapa === "Selecciona" ) {
        this.message = "Selecciona una etapa";
        this.messageType = 'error';
        return;
      }
      const formData = new FormData();
      formData.append('folio', this.folio);
      formData.append('etapa', this.etapa);
      formData.append('archivo', this.archivo);

      try {
        await axios.post(`${API_URL}/api/carga-archivos`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        this.message = 'Archivo cargado correctamente';
        this.messageType = 'success';
        this.fetchUploadedFiles(); // Actualizar la lista de archivos cargados
        this.etapa = 'Selecciona';
        this.archivo = null;
      } catch (error) {
        console.error('Error en carga de archivos:', error);
        this.message = 'Error al subir archivo';
        this.messageType = 'error';
      }
    },
    async fetchUploadedFiles() {
      // Verifica si el folio está vacío
      if (!this.folio) {
        this.archivosCargados = []; // Deja la tabla en blanco
        this.etapa = 'Selecciona'; // Reinicia la etapa si no hay folio
        return; // Sale de la función
      }
      const timestamp = new Date().getTime(); // Genera un timestamp
      this.loading = true;
      try {
        const { data } = await axios.get(`${API_URL}/api/carga-archivos/consulta-etapa?folio=${this.folio}&_=${timestamp}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          'Cache-Control': 'no-cache', // Agrega este encabezado
        });
        this.message = 'Lista de archivos desplegada con exito';
        this.messageType = 'success';

        console.log('Datos de archivos cargados:', data); // Imprime los datos en la consola
        this.archivosCargados = data; // Almacena los archivos cargados en la variable
        this.etapa = data.length > 0 ? data[0].etapa : '';

        if (data.length > 0) {
          this.archivosCargados = data; // Almacena los archivos cargados en la variable
          this.etapa = data[0].etapa; // Establece la etapa del primer archivo
          this.previousFolio = this.folio; // Actualiza el folio anterior
          //this.etapa = data[0].etapa; // Suponiendo que la etapa está en el primer objeto del array
        } else {
          if (this.previousFolio !== this.folio) {
            this.archivosCargados = []; // Limpia la tabla si no hay archivos
            this.etapa = 'Selecciona'; // Reinicia la etapa si no hay archivos
            this.message = 'No se encontraron archivos para este folio.';
            this.messageType = 'error';
          }
        }
      } catch (error) {
        console.error('Error al obtener archivos cargados:', error);
        this.message = 'No hay archivos cargados.';
        this.messageType = 'error';
        if (this.previousFolio !== this.folio) {
          this.archivosCargados = []; // Limpia la tabla si no hay archivos
          this.etapa = 'Selecciona'; // Reinicia la etapa si no hay archivos
          this.previousFolio = this.folio; // Actualiza el folio anterior
        }
      } finally {
        this.loading = false;
      }
    },
    async deleteFile(folio, etapa) {
      try {
        await axios.delete(`${API_URL}/api/carga-archivos/borrar?folio=${folio}&etapa=${etapa}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        this.message = 'Archivo borrado correctamente';
        this.messageType = 'success';

        // Actualiza la lista de archivos cargados
        this.fetchUploadedFiles(); // Si quieres hacer una nueva llamada al servidor

        // O bien, elimina el archivo de la lista localmente
        this.archivosCargados = this.archivosCargados.filter(item => item.folio !== folio || item.etapa !== etapa);

        this.archivo = null;
        this.etapa = 'Selecciona';
        // Si quieres asegurarte de que la tabla esté vacía, puedes hacer
        if (this.archivosCargados.length === 0) {
          this.etapa = ''; // Limpiar etapa si no hay archivos
        }

      } catch (error) {
        console.error('Error al borrar archivo:', error);
        this.message = 'Error al borrar el archivo';
        this.messageType = 'error';
      }
    },
    resetForm() {
      this.folio = '';
      this.etapa = '';
      this.archivo = null;
      this.message = '';
    },
  },
  mounted() {
    this.fetchUploadedFiles(); // Llama a esta función cuando el componente se monta para cargar archivos previamente cargados
  },
};
</script>

<style scoped>
.v-card {
  max-width: 600px;
  margin: 0 auto;
}

.v-card-title {
  justify-content: center;
}
.custom-bg {
  background-color: #009496;
}
.v-btn {
  margin-left: 10px;
}
</style>
