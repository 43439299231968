<template>
  <v-container>
    <v-card class="elevation-3">
      <v-card-title class="custom-bg">
        <span class="headline white--text">Administración de Usuarios</span>
        <v-spacer></v-spacer>
        <v-btn @click="exportToExcel" color="white" class="mx-2">
          <v-icon>mdi-download</v-icon>&nbsp;&nbsp;Exportar a Excel
        </v-btn>
        <v-btn @click="openDialog(null)" color="white">
          <v-icon>mdi-account</v-icon>&nbsp;&nbsp;Nuevo Usuario
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
        ></v-text-field>
        <v-data-table
            :headers="headers"
            :items="usuarios"
            :search="search"
            :custom-filter="customFilter"
            :items-per-page="10"
            class="elevation-1"
        >
        
        
          <template v-slot:item="{ item }">
            <tr>
              <td><v-icon>mdi-account</v-icon></td>              
              <td class="text-center">{{ item.clientName }}</td>
              <td class="text-center">{{ item.departmentName }}</td>
              <td class="text-center"><strong>{{ item.username }}</strong></td>
              <td class="text-center"><a :href="'mailto:' + item.email">{{ item.email }}</a></td>
              <td class="text-center switch-container">
                <v-switch
                    v-model="item.acceso_todas_sucursales"
                    :label="item.acceso_todas_sucursales ? 'Sí' : 'No'"
                    @change="updateAcceso(item)"
                ></v-switch>
              </td>
              <td class="text-center">
                <v-chip :color="getChipColor(item.rol)" dark>
                  <v-icon v-if="item.rol === 'cliente'" small>mdi-account</v-icon>
                  <v-icon v-else-if="item.rol === 'administrador'" small>mdi-shield-account</v-icon>
                  &nbsp; {{ item.rol }}
                </v-chip>
              </td>
              <td class="text-center">{{ item.last_login }}</td>

              <td class="text-center">
                <v-icon small @click="openDialog(item)" class="mx-1">mdi-pencil</v-icon>
                <v-icon small @click="deleteItem(item)" class="mx-1">mdi-delete</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="primary">
          <span class="headline white--text">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-autocomplete
                v-model="editedItem.cve_cte"
                :items="serviceClients"
                item-text="nom_cte"
                item-value="cve_cte"
                label="Cliente"
                required
                @change="fetchDepartmentsByClient(editedItem.cve_cte)"
            ></v-autocomplete>
            <v-select
                v-model="editedItem.id_sucursal"
                :items="departments"
                item-value="cve_entre"
                item-text="nom_ent"
            label="Sucursal"
            required
            ></v-select>


            <v-text-field v-model="editedItem.username" label="Usuario" required></v-text-field>
            <v-text-field v-model="editedItem.email" label="Correo" required></v-text-field>
            <v-select v-model="editedItem.rol" :items="roles" label="Rol" required></v-select>
            <v-switch
                v-model="editedItem.acceso_todas_sucursales"
                label="Acceso a todas las sucursales"
            ></v-switch>
            <v-text-field v-model="editedItem.password" label="Contraseña" :type="showPassword ? 'text' : 'password'" required></v-text-field>
            <v-checkbox v-model="showPassword" label="Mostrar Contraseña"></v-checkbox>

          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog"><v-icon left>mdi-close</v-icon> Cancelar</v-btn>
          <v-btn text @click="saveItem"><v-icon left>mdi-content-save</v-icon> Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
const API_URL = process.env.VUE_APP_API_URL;
export default {
  data() {
    return {
      dialog: false,
      showPassword: false,
      search: '',
      usuarios: [],
      serviceClients: [],
      departments: [],
      departmentName: [],
      editedIndex: -1,
      editedItem: {
        id_usuario: null,
        cve_cte: '',
        departmentName: '',
        id_sucursal: '',
        username: '',
        email: '',
        rol: '',
        password: '',
        acceso_todas_sucursales: false
      },
      defaultItem: {
        id_usuario: null,
        cve_cte: '',
        departmentName: '',
        id_sucursal: '',
        username: '',
        email: '',
        rol: '',
        password: '',
        acceso_todas_sucursales: false
      },
      headers: [
        { text: '', align: 'center', value: 'ttest' },
        { text: 'Clave Cliente', align: 'center', value: 'cve_cte' },
        { text: 'Ubicación', align: 'center', value: 'departmentName' },
        { text: 'Usuario', align: 'center', value: 'username' },
        { text: 'Correo', align: 'center', value: 'email' },
        { text: 'Acceso a todas las sucursales', align: 'center', value: 'acceso_todas_sucursales' },
        { text: 'Rol', align: 'center', value: 'rol' },
        { text: 'Ultimo Inicio de Sesion', align: 'center', value: 'last_login' },
        { text: 'Acciones', align: 'center', value: 'actions', sortable: false }
      ],
      roles: ['cliente', 'administrador']
    };
  },
  computed: {
    dialogTitle() {
      return this.editedItem.id_usuario ? 'Editar Usuario' : 'Nuevo Usuario';
    },
    ...mapState(['accessToken', 'cve_cte', 'cve_entre', 'apiUrl']),
  },
  created() {
    this.fetchData();
    this.fetchServiceClients();
  },
  methods: {
    customFilter(value, search, item) {
      return (
          item.clientName.toLowerCase().includes(search.toLowerCase()) ||
          item.username.toLowerCase().includes(search.toLowerCase()) ||
          item.email.toLowerCase().includes(search.toLowerCase()) ||
          item.rol.toLowerCase().includes(search.toLowerCase()) ||
          item.departmentName.toLowerCase().includes(search.toLowerCase()) ||
          ('' + item.id_sucursal).includes(search)
      );
    },
    fetchData() {
      axios
          .get(`${API_URL}/api/users`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(async response => {
            const mappedUsuarios = await Promise.all(
                response.data.map(async usuario => {
                  const client = this.serviceClients.find(
                      client => client.cve_cte === usuario.cve_cte
                  );
                  const clientName = client ? client.nom_cte : 'Desconocido';

                  try {
                    const responseDept = await axios.get(
                        `${API_URL}/api/service-clients-department/${usuario.cve_cte}/${usuario.id_sucursal}`,
                        {
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                          }
                        }
                    );
                    const departmentName = responseDept.data.nom_ent || 'Desconocido';

                    return {
                      ...usuario,
                      clientName,
                      departmentName
                    };
                  } catch (error) {
                    console.error('Error al obtener el departamento:', error);
                    return {
                      ...usuario,
                      clientName,
                      departmentName: 'Desconocido'
                    };
                  }
                })
            );

            this.usuarios = mappedUsuarios;
          })
          .catch(error => {
            console.error('Error al obtener usuarios:', error);
          });
    },

    fetchServiceClients() {
      axios
          .get(`${API_URL}/api/service-clients`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(response => {
            this.serviceClients = response.data;
            this.fetchData();
            this.fetchServiceClients.cve_cte;
          })
          .catch(error => {
            console.error('Error al obtener service clients:', error);
          });
    },
    fetchDepartmentsByClient(clientId) {
      axios
          .get(`${API_URL}/api/service-clients-department/${clientId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          })
          .then(response => {
            this.departments = response.data; // Actualiza los departamentos
          })
          .catch(error => {
            console.error('Error al obtener los departamentos:', error);
          });
    },
    openDialog(item) {
      if (item) {
        this.editedIndex = this.usuarios.indexOf(item);
        this.editedItem = { ...item };
      } else {
        this.editedIndex = -1;
        this.editedItem = { ...this.defaultItem };
      }
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.form.reset();
    },
    async saveItem() {
      const isNewUser = !this.editedItem.id_usuario;


      const apiUrl = isNewUser
          ? `${API_URL}/api/users`
          : `${API_URL}/api/users/${this.editedItem.id_usuario}`;
      const httpMethod = isNewUser ? 'post' : 'put';

      try {
        const response = await axios[httpMethod](apiUrl, this.editedItem, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (isNewUser) {
          this.usuarios.push(response.data);
        } else {
          Object.assign(this.usuarios[this.editedIndex], response.data);
        }

        this.closeDialog();
        this.fetchData(); // Fetch the data again after saving
      } catch (error) {
        console.error('Error al guardar el usuario:', error);
      }
    },
    deleteItem(item) {
      const apiUrl = `${API_URL}/api/users/${item.id_usuario}`;

      axios
          .delete(apiUrl, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(() => {
            const index = this.usuarios.indexOf(item);
            this.usuarios.splice(index, 1);
          })
          .catch(error => {
            console.error('Error al borrar el usuario:', error);
          });
    },
    updateAcceso(item) {
      const apiUrl = `${API_URL}/api/users/${item.id_usuario}`;

      axios
          .put(apiUrl, item, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          })
          .then(response => {
            console.log('Acceso actualizado:', response.data);
          })
          .catch(error => {
            console.error('Error al actualizar el acceso:', error);
          });
    },
    getChipColor(rol) {
      return rol === 'cliente' ? 'blue' : 'green';
    },
    exportToExcel() {
      const worksheet = XLSX.utils.json_to_sheet(this.usuarios);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Usuarios');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      saveAs(blob, 'usuarios.xlsx');
    },
    validateUser(username, password) {
      const usernameRegex = /^[a-zA-Z0-9-]+$/;
      if (!usernameRegex.test(username)) {
        return { valid: false, message: 'El nombre de usuario contiene caracteres no permitidos. Solo se permite "-" como caracter especial.' };
      }
      if (password.length < 12) {
        return { valid: false, message: 'La contraseña debe tener al menos 12 caracteres.' };
      }
      return { valid: true, message: '' };
    }
  }
};
</script>

<style scoped>
.switch-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-bg {
  background-color: #009496;
}


</style>
