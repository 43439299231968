<template>

  <v-container>
    <v-btn type="button" class="v-btn-geen" color="#009496" text-color="white" @click="regresar" style="margin-top: 10px; align-self: center;">
      Regresar
    </v-btn>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel v-for="(faq, index) in faqs" :key="index">
        <v-expansion-panel-header class="full-width-background">
          <span class="faq-question"><b>{{ faq.question }}</b></span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="faq-answer">
                    <span v-html="formatAnswer(faq.answer)"></span>
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
export default {
  name: 'FaqView',
  data() {
    return {
      panel: null,
      faqs: [
        { question: '1.-¿Cómo genero mi usuario?', answer: 'Envía un correo a <a href="mailto:sistemas@grupocormac.com">sistemas@grupocormac.com</a> con los siguientes datos:\n\nNombre de Administrador:\nTeléfono:\nCorreo:\nEmpresa:\nSucursal (en caso de tener)' },
        { question: '2.-¿Qué períodos de fechas están disponibles para consulta de órdenes de servicio?', answer: 'Todo folio generado a partir de 1 de noviembre 2024' },
        { question: '3.-¿Por cuánto tiempo estarán vigentes mis folios Cormac?', answer: '1 año de vigencia' },
        { question: '4.-¿Qué hacer si no encuentro alguno de mis folios activos?', answer: 'Contactar a tu asesor de ventas asignado o a servicio al cliente' },
        { question: '5.-¿Cómo restablezco mi contraseña?', answer: 'Envía correo a <a href="mailto:sistemas@grupocormac.com">sistemas@grupocormac.com</a> con la solicitud y espera la respuesta.' },
        { question: '6.-¿Qué información de mi servicio puedo encontrar en la plataforma?', answer: 'Folios Cormac activos, estatus de tus órdenes de servicio, cotizaciones, guía de envío, paquetería, fotos de recepción y envío de tus equipos, certificados de calibración (en caso de aplicar), constancia de servicio, historial generado de tus órdenes de servicio a partir del 1 de noviembre a la fecha actual (con un año de vigencia).' },
        { question: '7.-¿Qué filtros de búsqueda puedo aplicar para encontrar mis órdenes de servicio?', answer: 'Puedes buscar por fecha, por folio Cormac, por marca, modelo, tipo de equipo y número de serie.' },
        { question: '8.-¿Cuánto tiempo tengo para descargar mis archivos?', answer: '30 días a partir de la fecha de finalización del folio Cormac.' },
        { question: '9.-¿Qué puedo hacer si mis archivos de descarga ya no están disponibles?', answer: 'Los archivos de descarga estarán disponibles en la plataforma solamente 30 días posteriores a la finalización del folio. Después de esto, tendrás 30 días adicionales para solicitarlo con tu asesor de ventas. Finalizados estos días, los archivos serán borrados permanentemente.' },
      ],

    };
  },
  methods: {
    formatAnswer(answer) {
      // Esta función reemplaza las nuevas líneas por etiquetas <br>
      return answer.replace(/\n/g, '<br>');
    },
    regresar() {
      this.$router.push({ name:'ConsultaFoliosView'});
    },
  },

};
</script>

<style scoped>
.full-width-background {
  background-color: #009496;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.faq-question {
  font-size: 18px; /* Cambia este valor al tamaño deseado */
}

.faq-answer {
  white-space: pre-line; /* Esto permite que los saltos de línea se respeten */
}
</style>
