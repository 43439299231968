import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '../src/store/store.js';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/lib/locale/es';

Vue.use(Vuetify); // Registra Vuetify una vez

// Verificar si hay un token en localStorage al cargar la app
const token = localStorage.getItem('token');
if (token) {
  store.commit('SET_ACCESS_TOKEN', token);
  store.commit('SET_LOGGED_IN', true);
  // Verificar el token al cargar la aplicación
  store.dispatch('verifyToken');
}

// Manejo de errores global en la aplicación
Vue.config.errorHandler = function (err, vm, info) {
  console.error(`Error: ${err.toString()}\nInfo: ${info}`);
};

const vuetify = new Vuetify({
  lang: {
    locales: { es },
    current: 'es',
  },
});

// Instancia principal de Vue
new Vue({
  router,
  store,
  vuetify, // Aquí usas la instancia de Vuetify creada arriba
  render: h => h(App)
}).$mount('#app');
