<template>
  <v-app>
    <v-main>
      <!-- Contenido principal, donde se renderizan las vistas -->
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
//import MenuView from './components/MenuView.vue';  // Importa el componente MenuView

export default {
  name: 'App',
  computed: {
    ...mapState(['loggedIn','userRole']),
  },

  watch: {
    loggedIn(newValue) {
      // Redirigir al login si no está autenticado
      if (!newValue && this.$router.currentRoute.name !== 'LoginView') {
        this.$router.push({name: 'LoginView'});
      }
    },
  },
};
</script>

<style>
/* Estilos adicionales según necesidades */
.custom-header {
  background-color: #009496 !important;
}
</style>
