import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from '../router';

Vue.use(Vuex);

const token = localStorage.getItem('token'); // Obtiene el token del localStorage
const API_URL = process.env.VUE_APP_API_URL;

export default new Vuex.Store({

    state: {
        loggedIn: !!token,
        accessToken: token || null,
        userRol: '',
        cve_cte: null,
        cve_entre: null,
        id: null
    },
    getters: {
        getApiUrl: (state) => state.apiUrl,
    },
    mutations: {
        SET_LOGGED_IN(state, loggedIn) {
            state.loggedIn = loggedIn;
        },
        SET_ACCESS_TOKEN(state, accessToken) {
            state.accessToken = accessToken;
        },
        SET_ROL(state, rol) {
            state.rol = rol;
        },
        SET_USER_INFO(state, { cve_cte, cve_entre }) {
            state.cve_entre = cve_entre;
            state.cve_cte = cve_cte;
        }
    },
    actions: {
        login({ commit }, { username, password }) {
            return new Promise((resolve, reject) => {
                axios.post(`${API_URL}/api/auth/login`, {
                    username,
                    password
                })
                    .then(response => {
                        const { accessToken, rol, cve_cte, cve_entre } = response.data;
                        commit('SET_ACCESS_TOKEN', accessToken);
                        commit('SET_ROL', rol);
                        commit('SET_USER_INFO', {cve_cte, cve_entre});
                        commit('SET_LOGGED_IN', true);
                        localStorage.setItem('token', accessToken);
                        router.push({ name: 'Dashboard' });
                        resolve(response);
                    })
                    .catch(error => {
                        console.error('Error al iniciar sesión:', error);
                        reject(error);
                    });
            });
        },
        async verifyToken({ commit, state }) {
            if (state.accessToken) {
                try {
                    // Llama a la API para verificar si el token sigue siendo válido
                    await axios.get('${API_URL}/api/auth/verify-token', {
                        headers: {'Authorization': `Bearer ${state.accessToken}`}
                    });
                } catch (error) {
                    console.error('Token inválido, redirigiendo al login:', error);
                    commit('SET_LOGGED_IN', false);
                    commit('SET_ACCESS_TOKEN', null);
                    localStorage.removeItem('token');
                    router.push('/login');
                }
            }
        },
        logout({ commit }) {
            commit('SET_LOGGED_IN', false);
            commit('SET_ACCESS_TOKEN', null);
            commit('SET_ROL', null);
            commit('SET_USER_INFO', {cve_cte:null, cve_entre:null});
            localStorage.removeItem('token');
            if (router.currentRoute.path !== '/login') {
                router.push('/login');
            }

        }
    },
});
